import React from 'react';

import "../../../styles/ServicesHoverCards.scss"

function AggregatesHoverCards() {
    const hoverDefault = " "
    let [hover, setHover] = React.useState(hoverDefault);
    let t : any;
    return (
        <div className={"conContainer"}>
            <div onMouseEnter={() => {t = setTimeout(()=>{ setHover("1")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "1" ? <div className={"blur"}>

                <p>
                    The 4 ways of sampling defined by AS 1012.1 is used to ensure the accuracy and credibility.
                </p>
            </div> : <div>  <h1>Sampling Concrete</h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("2")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "2" ? <div className={"blur"}>
                <p> By Following AS1013.1 </p>
                <p>
                    This test is taken by filling a slump cone with fresh concrete in layers, rodding each layer and then removing the support given to the concrete by the slump cone.
                    The vertical subsidence of the concrete that occurs, when the slump cone is raised, is termed the “slump” of the concrete
                </p>
            </div> : <div> <h1> Concrete Testing </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("3")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "3" ? <div className={"blur"}>
                <p>
                    We follow AS 1012.5 and AS 1012.12.1 to determine the mass per unity volume of fresh or hardened concrete by Rodding or vibration.
                </p>
            </div> : <div> <h1> Determination of the mass per unit volume </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("4")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "4" ? <div className={"blur"}>
                <p>
                    Following AS 1012.9 and 1012.14 This test sets out to determine the compressive strength of concrete test specimens
                </p>
            </div> : <div> <h1> Compressive Strength </h1>

            </div>} </div>
            {/*
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("5")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "5" ? <div className={"blur"}>
                <p> These tests </p>
                <p>
                    All insure the material tested is not to flaky and there is enough angularity in the aggregate to play its important role in
                    maximum mechanical interlocking.
                </p>
            </div> : <div> <h1> Proportional calliper, flakiness index, average least dimension, crushed faces and weak particles </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("6")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={ConcreteCardsCss.hoverBox}> {hover === "6" ? <div className={ConcreteCardsCss.blur}>
                <p>
                    These tests are performed to determine the strength of the most predominant aggregate size that is being used in the material
                    by the use of compression machine or the pulverising of the material.
                </p>
            </div> : <div> <h1> Aggregate crushing value, wet/dry strength variation and los angeles value </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("7")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={ConcreteCardsCss.hoverBox}> {hover === "7" ? <div className={ConcreteCardsCss.blur}>
                <p>
                    This involves the testing of the intermediate sizes of the material by finding out what fines will be
                    generated by putting the raw material through a self abrasion process.
                </p>
            </div> : <div> <h1> Degradation Factor </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("8")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={ConcreteCardsCss.hoverBox}> {hover === "8" ? <div className={ConcreteCardsCss.blur}>
                <p>
                    bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                    ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                </p>
            </div> : <div> <h1> Filler Title </h1>

            </div>} </div> */}
        </div>
    );
}

export default AggregatesHoverCards;